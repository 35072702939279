html {
  scroll-behavior: smooth;
}

/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
} */

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-size: calc(10px + 2vmin);
  color: white;
}

/* .App-link {
  color: #09d3ac;
} */

.page-wrapper {
  padding: 50px 0;
  background-color: #f5f5f5;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.page-grid {
  width: 100%;
}

.sk-fade-in-quarter-second {
  display: flex;
  flex-direction: row;
}

.page-name {
  text-align: center;
  text-transform: uppercase;
}

.ant-form-item {
  margin-bottom: 10px !important;
}

.react-tel-input input[type="tel"],
.ant-select-selection,
.ant-input,
.ant-input[disabled] {
  color: #000 !important;
  border-radius: 0 !important;
}

.ant-form-explain {
  margin-left: 16.67%;
}

.ant-select-focused .ant-select-selection,
.ant-select-selection:focus,
.ant-select-selection:active,
.ant-select-selection:hover,
.ant-input:focus,
.ant-input:active,
.ant-input:hover {
  border-color: #dadada !important;
  box-shadow: 0 0 0 2px rgba(218, 218, 218, 0.2) !important;
}

.ant-select-dropdown-menu-item-selected,
.ant-select-dropdown-menu-item-active,
.ant-select-dropdown-menu-item-selected:hover {
  background-color: #dadada !important;
}

.button-wrapper-center {
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 1199px) {
  .ant-form-explain {
    margin-left: 0;
  }
  .ant-form-item {
    margin-bottom: 5px !important;
  }
}
